import React from "react";
import graphicCogs from "../img/graphic-cogs.svg";
import graphicDesign from "../img/graphic-design.svg";
import graphicIco from "../img/graphic-ico.svg";
import graphicBlockchain from "../img/graphic-blockchain.svg";
import graphicTool from "../img/graphic-tool.svg";

export const ServicesContainer = () => {
  return (
    <section class="Services__container" id="services">
      <div class="Services__title">
        <h3>TRANSFORMATION</h3>
        <h1>Services We Offer</h1>
      </div>

      <div class="Services__row --single">
        <div class="Services__service">
          <img src={graphicCogs} draggable="false" alt="graphicCogs" />
          <h3>BACKEND SYSTEMS</h3>
          <p>Integrating distributed or decentralised back-end systems</p>
          <div class="Services__service__line"></div>
          <p>
            Distributing or decentralising a system yields a number of desirable
            properties.
          </p>
        </div>
      </div>

      <div class="Services__row --multiple">
        <div class="Services__service">
          <img src={graphicDesign} draggable="false" alt="graphicDesign" />
          <h3>SMART CONTRACTS</h3>
          <p>Deploying Ethereum smart contracts, DApps, and tokens</p>
          <div class="Services__service__line"></div>
          <p>
            Whether you're designing a new app or launching a new token we have
            you covered.
          </p>
        </div>

        <div class="Services__service">
          <img src={graphicIco} draggable="false" alt="graphicIco" />
          <h3>ICO</h3>
          <p>ICO research and development</p>
          <div class="Services__service__line"></div>
          <p>
            Our team of researchers can become a resource for your
            cryptocurrency start-up.
          </p>
        </div>

        <div class="Services__service">
          <img
            src={graphicBlockchain}
            draggable="false"
            alt="graphicBlockchain"
          />
          <h3>PRIVATE BLOCKCHAINS</h3>
          <p>Implemented private blockchains</p>
          <div class="Services__service__line"></div>
          <p>
            Our team has in-depth experience with the custom implementation of a
            variety of blockchain systems.
          </p>
        </div>
      </div>

      <div class="Services__row --single">
        <div class="Services__service">
          <img src={graphicTool} draggable="false" alt="graphicTool" />
          <h3>DESIGN</h3>
          <p>
            Designing websites, mobile applications, and front-end interfaces
          </p>
          <div class="Services__service__line"></div>
          <p>The end user is the central motivator for our work.</p>
        </div>
      </div>
    </section>
  );
};
