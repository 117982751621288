import React from "react";
import stably from "../../../img/stably.png";
import nodejs from "../../../img/nodejs-logo.svg";
import js from "../../../img/js.svg";

import php from "../../../img/php.svg";

import facebooKfBrands from "../../../img/facebook-f-brands.svg";
import linkedinInBrands from "../../../img/linkedin-in-brands.svg";

import { HashLink } from "react-router-hash-link";

export const Stably = () => {
  return (
    <div className="Site__container">
      <div className="project--stab">
        <section className="project">
          <div className="project__title">
            <h3>Dollar-backed Cryptocurrency</h3>
            <h2>Stably</h2>
          </div>
          <div className="browser">
            <div className="browser--extrabar"></div>
            <div className="browser__screen">
              <img src={stably} alt="screen" />
              <div></div>
            </div>
          </div>
        </section>
        <section className="project__description">
          <div className="project__descriptionBar"></div>
          <div className="project__spacer"></div>
          <div className="wrap">
            <p className="p p--large">
              A startup that is creating a dollar reserve-backed cryptocurrency
              engineered to be verifiably transparent.
            </p>
          </div>
          <div className="wrap backgroundDotted">
            <h3>Our Strategy</h3>
            <p className="p p--medium">
              Stably requested that Gravitichain build a website that clearly
              conveys the company’s values of transparency and honesty through a
              professional landing page.
            </p>
            <p className="p p--medium">
              We pulled inspiration from Stably’s major competitors as a
              baseline. Then, we improved upon these designs with unique
              artwork, animations, and icons cohesive with Stably’s brand
              strategy.
            </p>
          </div>
          <div className="project__technologies">
            <div className="project__technologiesWrap">
              <div className="technology">
                <img src={nodejs} alt="" className="technology__logo" />
                <h5 className="technology__title">Node.js</h5>
              </div>
              <div className="technology__description">
                <h5>Node.js:</h5>
                <p className="p p--normal">
                  Node.js is a JavaScript runtime built on Chrome's V8
                  JavaScript engine and is designed to build scalable network
                  applications.
                </p>
                <a href="https://nodejs.org/en/">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={js} alt="" className="technology__logo" />
                <h5 className="technology__title">JavaScript</h5>
              </div>
              <div className="technology__description">
                <h5>JavaScript:</h5>
                <p className="p p--normal">
                  Along with HTML and CSS, JavaScript is one of the three core
                  technologies of the World Wide Web. JavaScript enables
                  interactive web pages and thus is an essential part of web
                  applications.
                </p>
                <a href="https://www.javascript.com">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={php} alt="" className="technology__logo" />
                <h5 className="technology__title">PHP</h5>
              </div>
              <div className="technology__description">
                <h5>PHP:</h5>
                <p className="p p--normal">
                  A server-side scripting language designed for Web development
                  that is also used as a general-purpose programming language.
                </p>
                <a href="http://php.net">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="project__callToAction">
          <div className="wrap">
            <h2>Need a successful project?</h2>
            <HashLink
              to="/#contact"
              title="Discuss your Project"
              className="button button--transparent"
            >
              WE CAN DO IT!
            </HashLink>
          </div>
        </section>
      </div>
      <section className="Footer__container --portfolio">
        <div className="Footer__content">
          <ul>
            <li>
              <a href="/#services" title="Services">
                Services
              </a>
            </li>
            <li>
              <a href="/portfolio" title="Our Portfolio">
                Our Portfolio
              </a>
            </li>
            <li>
              <a href="/#process" title="Our Collaboration Process">
                Our Process
              </a>
            </li>
            <li>
              <a href="/#pricing" title="Our Pricing Model">
                Pricing Model
              </a>
            </li>
            <li>
              <a href="/#contact" title="Discuss your Project">
                Contact
              </a>
            </li>
          </ul>
          <p>
            <a
              className="FooterSocial"
              href="https://www.facebook.com/GravitiChain/"
            >
              <button className="Hero__social__facebook">
                <img
                  src={facebooKfBrands}
                  draggable="false"
                  alt="facebooKfBrands"
                />
              </button>
            </a>
            <a
              className="FooterSocial"
              href="https://www.linkedin.com/company/gravitichain/"
            >
              <button className="Hero__social__linkedin">
                <img
                  src={linkedinInBrands}
                  draggable="false"
                  alt="linkedinInBrands"
                />
              </button>
            </a>
            © 2018 Gravitichain studios. All rights reserved.
            <br />
            <a
              className="FooterSocial --mobile"
              href="https://www.facebook.com/GravitiChain/"
            >
              <button className="Hero__social__facebook">
                <img
                  src={facebooKfBrands}
                  draggable="false"
                  alt="facebooKfBrands"
                />
              </button>
            </a>
            <a
              className="FooterSocial --mobile"
              href="https://www.linkedin.com/company/gravitichain/"
            >
              <button className="Hero__social__linkedin">
                <img
                  src={linkedinInBrands}
                  draggable="false"
                  alt="linkedinInBrands"
                />
              </button>
            </a>
          </p>
        </div>
      </section>
    </div>
  );
};
