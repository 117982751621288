import React from "react";
import { PortfolioContainer } from "./PortfolioContainer";
import { PricingContainer } from "./PricingContainer";
import { ServicesContainer } from "./ServicesContainer";
import { CollabContainer } from "./CollabContainer";
import { ContactContainer } from "./ContactContainer";
import { DevContainer } from "./DevContainer";
import { FooterContainer } from "./FooterContainer";
import { HeroContainer } from "./HeroContainer";

export const MainContainer = () => {
  return (
    <div>
      <HeroContainer />
      <DevContainer />
      <ServicesContainer />
      <PortfolioContainer />
      <CollabContainer />
      <PricingContainer />
      <ContactContainer />
      <FooterContainer />
    </div>
  );
};
