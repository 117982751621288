import React from "react";

export const Sitebg = () => {
  return (
    <div className="Site__bg">
      <div className="Site__bg__hero">
        <div className="Site__bg__hero__cubes"></div>
        <div className="Site__bg__hero__cubes"></div>
        <div className="Site__bg__hero__dots"></div>
      </div>

      <div className="Site__bg__blue__container">
        <div className="Site__bg__blue">
          <div className="Site__bg__blue__swirl"></div>
          <div className="Site__bg__blue__swirl"></div>
          <div className="Site__bg__blue__pink"></div>
          <div className="Site__bg__blue__pink"></div>
          <div className="Site__bg__blue__pink"></div>
          <div className="Site__bg__blue__pink"></div>
          <div className="Site__bg__blue__dots"></div>
          <div className="Site__bg__blue__name"></div>
          <div className="Site__bg__blue__end"></div>
        </div>
      </div>

      <div className="Site__bg__bottom">
        <div className="Site__bg__bottom__pink"></div>
        <div className="Site__bg__bottom__pink"></div>
        <div className="Site__bg__bottom__name"></div>
        <div className="Site__bg__bottom__dots"></div>
        <div className="Site__bg__bottom__swirl"></div>
      </div>
    </div>
  );
};
