import React from "react";
import nbScreeens from "../../img/nbs_screeens.png";
import logoNvest from "../../img/logo_nvest.png";
import snScreens from "../../img/sn_screens.png";
import logoShared from "../../img/logo_shared.png";
import gxScreens from "../../img/gx_screens.png";
import logoGlobal from "../../img/logo_global.png";
import sScreens from "../../img/s_screens.png";
import logoStably from "../../img/logo_stably.png";
import ngeScreens from "../../img/nge_screens.png";
import amScreens from "../../img/am_screens.png";
import logoAcid from "../../img/logo_acid.png";
import nbScreens from "../../img/nb_screens.png";
import facebooKfBrands from "../../img/facebook-f-brands.svg";
import linkedinInBrands from "../../img/linkedin-in-brands.svg";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export const Portfolio = () => {
  return (
    <div>
      <div className="Site__container">
        <div class="Site__bg">
          <div class="Site__bg__portfolio">
            <div class="Site__bg__portfolio__swirl"></div>
            <div class="Site__bg__portfolio__pink"></div>
          </div>
        </div>
        <section className="FullPortfolio__container">
          <div className="FullPortfolio__title">
            <h3>GRAVITICHAIN</h3>
            <h1>Our Portfolio</h1>
          </div>
          <div className="FullPortfolio__content">
            <ul>
              <li className="FullPortfolio__content__item">
                <Link to="./nvestbanking">
                  <div className="FullPortfolio__content__item__bg"></div>
                  <img
                    src={nbScreeens}
                    draggable="false"
                    className="FullPortfolio__content__item__img"
                  />
                  <img
                    src={logoNvest}
                    draggable="false"
                    className="FullPortfolio__content__item__icon"
                  />
                  <div className="FullPortfolio__content__item__text">
                    <p>Nvest Online Banking</p>
                    <p>Cryptocurrency wallet and portfolio management system</p>
                  </div>
                </Link>
              </li>
              <li className="FullPortfolio__content__item">
                <Link to="./sharednodes">
                  <div className="FullPortfolio__content__item__bg"></div>
                  <img
                    src={snScreens}
                    draggable="false"
                    className="FullPortfolio__content__item__img"
                  />
                  <img
                    src={logoShared}
                    draggable="false"
                    className="FullPortfolio__content__item__icon"
                  />
                  <div className="FullPortfolio__content__item__text">
                    <p>Shared Nodes</p>
                    <p>
                      Selling shares in masternodes for proof-of-stake coins
                    </p>
                  </div>
                </Link>
              </li>
              <li className="FullPortfolio__content__item">
                <Link to="./globalxchange">
                  <div className="FullPortfolio__content__item__bg"></div>
                  <img
                    src={gxScreens}
                    draggable="false"
                    className="FullPortfolio__content__item__img"
                  />
                  <img
                    src={logoGlobal}
                    draggable="false"
                    className="FullPortfolio__content__item__icon"
                  />
                  <div className="FullPortfolio__content__item__text">
                    <p>Global X Change</p>
                    <p>
                      Cryptocurrency exchange with exotic blockchain-based
                      securities
                    </p>
                  </div>
                </Link>
              </li>
              <li className="FullPortfolio__content__item">
                <Link to="./stably">
                  <div className="FullPortfolio__content__item__bg"></div>
                  <img
                    src={sScreens}
                    draggable="false"
                    className="FullPortfolio__content__item__img"
                  />
                  <img
                    src={logoStably}
                    draggable="false"
                    className="FullPortfolio__content__item__icon"
                  />
                  <div className="FullPortfolio__content__item__text">
                    <p>Stably</p>
                    <p>Landing page for a USD stablecoin startup</p>
                  </div>
                </Link>
              </li>
              <li className="FullPortfolio__content__item">
                <Link to="./nvestglobal">
                  <div className="FullPortfolio__content__item__bg"></div>
                  <img
                    src={ngeScreens}
                    draggable="false"
                    className="FullPortfolio__content__item__img"
                  />
                  <img
                    src={logoNvest}
                    draggable="false"
                    className="FullPortfolio__content__item__icon"
                  />
                  <div className="FullPortfolio__content__item__text">
                    <p>Nvest Global Enterprises</p>
                    <p>
                      Landing page for cryptocurrency bank startup tailored to
                      investors
                    </p>
                  </div>
                </Link>
              </li>
              <li className="FullPortfolio__content__item">
                <Link to="./acidmarketing">
                  <div className="FullPortfolio__content__item__bg"></div>
                  <img
                    src={amScreens}
                    draggable="false"
                    className="FullPortfolio__content__item__img"
                  />
                  <img
                    src={logoAcid}
                    draggable="false"
                    className="FullPortfolio__content__item__icon"
                  />
                  <div className="FullPortfolio__content__item__text">
                    <p>ACID Marketing</p>
                    <p>
                      Website for a Canadian startup and ICO marketing company
                    </p>
                  </div>
                </Link>
              </li>
              <li className="FullPortfolio__content__item">
                <Link to="./nvestbank">
                  <div className="FullPortfolio__content__item__bg"></div>
                  <img
                    src={nbScreens}
                    draggable="false"
                    className="FullPortfolio__content__item__img"
                    alt="nbScreens"
                  />
                  <img
                    src={logoNvest}
                    draggable="false"
                    className="FullPortfolio__content__item__icon"
                    alt="logoNvest"
                  />
                  <div className="FullPortfolio__content__item__text">
                    <p>Nvest Bank</p>
                    <p>
                      Consumer facing website for retail cryptocurrency bank
                    </p>
                  </div>
                </Link>
              </li>
            </ul>

            <div className="FullPortfolio__content__bottom">
              <h1>Need a successful project?</h1>
              <HashLink
                to="/#contact"
                title="Discuss your Project"
                className="button button--transparent"
              >
                WE CAN DO IT!
              </HashLink>
            </div>
          </div>
        </section>

        <section className="Footer__container">
          <div className="Footer__content">
            <ul>
              <li>
                <HashLink to="/#services" title="Services">
                  Services
                </HashLink>
              </li>
              <li>
                <HashLink to="/#process" title="Our Collaboration Process">
                  Our Process
                </HashLink>
              </li>
              <li>
                <HashLink to="/#pricing" title="Our Pricing Model">
                  Pricing Model
                </HashLink>
              </li>
              <li>
                <HashLink to="/#contact" title="Discuss your Project">
                  Contact
                </HashLink>
              </li>
            </ul>
            <p>
              <Link
                className="FooterSocial"
                to="https://www.facebook.com/GravitiChain/"
              >
                <button className="Hero__social__facebook">
                  <img src={facebooKfBrands} draggable="false" />
                </button>
              </Link>
              <Link
                className="FooterSocial"
                to="https://www.linkedin.com/company/gravitichain/"
              >
                <button className="Hero__social__linkedin">
                  <img src={linkedinInBrands} draggable="false" />
                </button>
              </Link>
              © 2018 Gravitichain studios. All rights reserved.
              <br />
              <Link
                className="FooterSocial --mobile"
                to="https://www.facebook.com/GravitiChain/"
              >
                <button className="Hero__social__facebook">
                  <img src={facebooKfBrands} draggable="false" />
                </button>
              </Link>
              <Link
                className="FooterSocial --mobile"
                to="https://www.linkedin.com/company/gravitichain/"
              >
                <button className="Hero__social__linkedin">
                  <img src={linkedinInBrands} draggable="false" />
                </button>
              </Link>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};
