export const mobilePortfolio = [
  {
    header: "Nvest Online Banking",
    subHeader: "Cryptocurrency wallet and portfolio management system",
    img: "/assets/images/mobile_banksystem.png",
    url: "nvestbanking",
  },
  {
    header: "Shared Nodes",
    subHeader: "Selling shares in masternodes for proof-of-stake coins",
    img: "/assets/images/mobile_shared.png",
    url: "sharednodes",
  },
  {
    header: "Global X Change",
    subHeader:
      "Cryptocurrency exchange with exotic blockchain-based securities",
    img: "/assets/images/mobile_global.png",
    url: "globalxchange",
  },
  {
    header: "Stably",
    subHeader: "Landing page for a USD stablecoin startup",
    img: "/assets/images/mobile_stably.png",
    url: "stably",
  },
  {
    header: "ACID Marketing",
    subHeader: "Website for a Canadian startup and ICO marketing company",
    img: "/assets/images/mobile_acid.png",
    url: "acidmarketing",
  },
  {
    header: "Nvest Bank",
    subHeader: "Consumer facing website for retail cryptocurrency bank",
    img: "/assets/images/mobile_bank.png",
    url: "nvestbank",
  },
];
