import React from "react";

export const CollabContainer = () => {
  return (
    <section class="Collab__container" id="process">
      <div class="Collab__content">
        <div class="Collab__content__header">
          <h3>COMMITMENT</h3>
          <h1>Our Collaboration Process</h1>
          <p>
            Our team of specialists can help you determine the technical needs
            of your proposed software. Whether your project is blockchain-based,
            a mobile application, or a collection of smart contracts our
            software developers understand the process and can guide you every
            step of the way.
          </p>
        </div>

        <ul>
          <li>
            <h2>Learn</h2>
            <p>
              First send us your white-paper, business plan, and technical
              documents and our company takes the time to learn the nuances of
              your business and the scope of your project.
            </p>
          </li>
          <li>
            <h2>Understand</h2>
            <p>
              Second we schedule a call to ask clarifying questions, receive
              your input, and offer a rough cost estimate.
            </p>
          </li>
          <li>
            <h2>Plan</h2>
            <p>
              Then we architect your system, make projections, and draft
              diagrams to give you an understanding of how we plan to tackle
              your project. At this point we send you a quote for the cost of
              your project.
            </p>
          </li>
          <li>
            <h2>Commit</h2>
            <p>
              You review our project proposal; If you decide to work with us
              then we convert your proposal into a legal agreement and begin
              development work.
            </p>
          </li>
          <li>
            <h2>Collaborate</h2>
            <p>
              Afterwards we work closely alongside your team to incorporate your
              feedback and deliver a product that is tailored to the customer's
              needs.
            </p>
          </li>
          <li>
            <h2>Deliver</h2>
            <p>
              We deliver the final product and discuss opportunities for
              continuous software improvement.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};
