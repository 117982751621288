import React, { useState } from "react";
import "./paymentpage.scss";

//images
import logo from "../../../img/paymentpage/logo.svg";
import starfishlogo from "../../../img/paymentpage/starfishlogo.svg";
import arrow from "../../../img/paymentpage/arrow.svg";
import razpay from "../../../img/paymentpage/razpay.svg";

const PaymentPage = () => {
  const [formstep, setFormStep] = useState(1);
  const [firstName, setFirstname] = useState();
  const [LastName, setLastname] = useState();
  const [emailId, setEmailId] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [country, setCountry] = useState();

  return (
    <div className="paymentpage-container">
      <div className="top-area">
        <div className="ellipse1"></div>
        <div className="navbar">
          <div className="line"></div>
        </div>
        <div className="top-container">
          <div>
            <img src={logo} alt="" />
          </div>
          <div>
            <span className="secure-txt">Secure Your Spot Now</span> <br />
            <span className="transform-txt">
              Transform Your Digital Marketing Strategy!
            </span>
          </div>
        </div>
      </div>
      <div className="payment-container">
        <div className="ellipse1"></div>
        <div className="payment-div">
          <div className="join-box">
            Join Digital Marketing Strategy Program for special price of Rs 499
            only
          </div>
          <div className="payment-form-div">
            <div className="heading-div">
              <div
                className="step1-div"
                style={{
                  borderTopLeftRadius: "3px",
                  background: formstep === 1 ? "white" : "#F4F4F4",
                  borderTop: formstep === 1 ? "1px solid black" : "",
                }}
                onClick={() => {
                  setFormStep(1);
                }}
              >
                <div className="num">1</div>
                <div className="step-details">
                  <div className="text1">Your Details</div>
                  <div className="text2">For Access</div>
                </div>
              </div>
              <div
                className="step1-div"
                style={{
                  borderTopRightRadius: "3px",
                  background: formstep === 2 ? "white" : "#F4F4F4",
                  borderTop: formstep === 2 ? "1px solid black" : "",
                }}
                onClick={() => {
                  setFormStep(2);
                }}
              >
                <div className="num">2</div>
                <div className="step-details">
                  <div className="text1">Payment</div>
                  <div className="text2">Of your session</div>
                </div>
              </div>
            </div>
            {formstep === 1 ? (
              <div className="payment-form">
                <div className="f-lname">
                  <div className="first-name-div">
                    <div className="field-name">
                      First name <span>*</span>
                    </div>
                    <div className="field">
                      <input
                        type="text"
                        className="field-input"
                        placeholder="First name *"
                        onChange={(e) => {
                          setFirstname(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="first-name-div"
                    style={{ paddingRight: "0", paddingLeft: "0.5rem" }}
                  >
                    <div className="field-name">
                      Last name <span>*</span>
                    </div>
                    <div className="field">
                      <input
                        type="text"
                        className="field-input"
                        placeholder="Last name *"
                        onChange={(e) => {
                          setLastname(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mob-email-div">
                  <div className="field-name">
                    First name <span>*</span>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      className="field-input"
                      placeholder="First name *"
                      onChange={(e) => {
                        setFirstname(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="mob-email-div">
                  <div className="field-name">
                    Last name <span>*</span>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      className="field-input"
                      placeholder="Last name *"
                      onChange={(e) => {
                        setLastname(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="email-div">
                  <div className="field-name">
                    Email address <span>*</span>
                  </div>
                  <div className="field">
                    <input
                      type="email"
                      className="field-input"
                      placeholder="Email address *"
                      onChange={(e) => {
                        setEmailId(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="email-div">
                  <div className="field-name">
                    Phone <span>*</span>
                  </div>
                  <div className="field">
                    <input
                      type="number"
                      className="field-input"
                      placeholder="Phone *"
                      onChange={(e) => {
                        setPhoneNo(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="email-div">
                  <div className="field-name">
                    Country/Region <span>*</span>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      className="field-input"
                      placeholder="United States (US)"
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                    />
                    <div className="down">
                      <img src={arrow} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="payment-form2">
                <div className="dottedline1">
                  <div style={{ fontWeight: "600" }}>Product</div>
                  <div style={{ fontWeight: "600" }}>Subtotal</div>
                </div>
                <div className="dottedline1" style={{ paddingTop: "1rem" }}>
                  <div>Growth Hacking On- Demand</div>
                  <div>₹499</div>
                </div>
                <div className="gst-box">
                  <div className="flex-div">
                    <div>Subtotal</div>
                    <div>₹499</div>
                  </div>
                  <div className="flex-div">
                    <div>GST</div>
                    <div>200</div>
                  </div>
                </div>
                <div
                  className="dottedline1"
                  style={{ paddingTop: "1rem", borderBottom: "none" }}
                >
                  <div style={{ fontWeight: "600" }}>Total</div>
                  <div style={{ fontWeight: "600" }}>₹699</div>
                </div>
                <div className="cc-section">
                  <div className="credit-div">
                    <div>Credit Card/Debit Card/Net Banking</div>
                    <div className="pay-by">
                      <div>Pay by</div>
                      <div>
                        <img src={razpay} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="raz-pay">
                    Pay Securely by Credit ot Debit card or Internet Banking
                    through Razorpay.
                  </div>
                </div>
                <div className="personal-text">
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our privacy policy
                </div>
              </div>
            )}
          </div>
          <div
            className="place-div"
            style={{ display: formstep === 2 ? "flex" : "none" }}
          >
            <div className="place-btn">Place Order ₹ 699</div>
          </div>
        </div>
        <div className="starfish-div">
          <div className="poweredby-div">
            <div>Powered by</div>
            <div>
              <img src={starfishlogo} alt="" />
            </div>
          </div>
          <div className="number-boxes">
            <div className="each-num-box">
              <div className="color-text">125+ Cr</div>
              <div className="sub-textt">Investment Raised</div>
            </div>
            <div className="each-num-box">
              <div className="color-text">150+ </div>
              <div className="sub-textt">Entrepreneurs Supported</div>
            </div>
            <div className="each-num-box">
              <div className="color-text">12</div>
              <div className="sub-textt">Current Portfolio</div>
            </div>
            <div className="each-num-box">
              <div className="color-text">25</div>
              <div className="sub-textt">Team Size</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
