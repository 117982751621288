import React from "react";

export const ContactContainer = () => {
  return (
    <section class="Contact__container" id="contact">
      <div class="Contact__content">
        <div class="Contact__content__body">
          <div class="Contact__content__body__left">
            <div class="Contact__content__title">
              <h3>CONTACT</h3>
              <h2>Tell Us About Your Project</h2>
            </div>
            <p>
              See how the gravitichain team can transform your project.
              <br />
              At the end of your 30 minute consultation, walk away with:
            </p>
            <ul>
              <li>Feedback on your implementation plan</li>
              <li>Insights into your project’s technical requirements</li>
              <li>
                Suggestions on minimising overhead and reducing go-to-market
                time
              </li>
              <li>
                Rough estimates on project cost and development time-frames
              </li>
            </ul>
          </div>

          <div class="Contact__content__body__right">
            <form
              action="sendmail.php"
              method="post"
              id="ajaxForm"
              class="Contact__content__body__right__form"
            >
              <h4>Get in touch</h4>
              <input
                type="text"
                class="input"
                name="name"
                req=""
                placeholder="Name"
              />
              <input
                type="email"
                class="input"
                name="email"
                req=""
                placeholder="Email Address"
              />
              <input
                type="text"
                class="input"
                name="phone"
                req=""
                placeholder="Phone Number"
              />
              <input
                type="text"
                class="input"
                name="business"
                placeholder="Business Name"
              />
              <input
                type="text"
                class="input"
                name="message"
                placeholder="Message"
              />
              <button>Let's talk</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
