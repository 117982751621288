import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./homepage.scss";

//images
import logo from "../../../img/Homepage/logo.svg";
import logo1 from "../../../img/Homepage/logo1.svg";
import mihir from "../../../img/Homepage/mihir.svg";
import social from "../../../img/Homepage/social.svg";
import star from "../../../img/Homepage/star.svg";
import star1 from "../../../img/Homepage/star1.svg";
import stars from "../../../img/Homepage/stars.svg";
import audit from "../../../img/Homepage/audit.svg";
import heading from "../../../img/Homepage/heading.svg";
import campaign from "../../../img/Homepage/campaign.svg";
import road from "../../../img/Homepage/road.svg";
import about from "../../../img/Homepage/about.svg";
import quote from "../../../img/Homepage/quote.svg";
import dummy1 from "../../../img/Homepage/dummy1.svg";
import dummy2 from "../../../img/Homepage/dummy2.svg";
import fb from "../../../img/Homepage/fb.svg";
import twitter from "../../../img/Homepage/twitter.svg";
import insta from "../../../img/Homepage/insta.svg";
import linkedin from "../../../img/Homepage/linkedin.svg";
import audience from "../../../img/Homepage/audience.svg";
import competitor from "../../../img/Homepage/competitor.svg";
import tick from "../../../img/Homepage/tick.svg";
import downArrow from "../../../img/Homepage/downArrow.svg";
import tick1 from "../../../img/Homepage/tick1.svg";
import tick2 from "../../../img/Homepage/tick2.svg";
import moblogo from "../../../img/MobileHomepage/moblogo.svg";
import downArrow1 from "../../../img/MobileHomepage/downArrow.svg";
import aboutImg from "../../../img/MobileHomepage/aboutImg.svg";

const HomePage = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState();
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  const boxesData = [
    {
      title: "360&deg; Audit",
      subhead: "Complete audit of your existing Digital Marketing Strategy",
      icon: audit,
    },
    {
      title: "Lead Gen Strategy",
      subhead:
        "Design your lead generation strategy to improve your cost per lead",
      icon: campaign,
    },
    {
      title: "Ad Copy Review",
      subhead: "Review of ad content, creative for maximum impact",
      icon: heading,
    },
    {
      title: "Audience Analysis",
      subhead: "Laser target your audience for maximum impact",
      icon: audience,
    },
    {
      title: "Competitor Analysis",
      subhead:
        "Figure out your competitor strategy & ethically steal their audience",
      icon: competitor,
    },
    {
      title: "Road Ahead",
      subhead: "Consultation on how to pave the road ahead.",
      icon: road,
    },
  ];

  const qnAndAns = [
    {
      ques: "What are we?",
      answer:
        "Gravitichain is an ecosystem-based intelligence firm, part of the startup studio Starfish. We specialize in providing growth solutions to startups at any stage, offering end-to-end support to help them scale from ideation to market launch.",
    },
    {
      ques: "What we do?",
      answer:
        "At Gravitichain, we establish customized sales and marketing strategies tailored to each client's unique needs, industry, and target audience. Our focus is on propelling startups to achieve rapid and sustainable growth in their respective markets.",
    },
    {
      ques: "How we do?",
      answer:
        "We combine our expertise in branding, marketing, growth strategy, and lead generation to create a comprehensive approach. By leveraging our vast network of investors, industry experts, and in-house tools, we nurture startups into thriving brands with strong brand identities and increased revenues. Through personalized solutions and a dedicated team, we empower startups to defy gravity and amplify their scale in the business cosmos.",
    },
  ];

  const testimonialData = [
    {
      pic: dummy1,
      content:
        "I couldn't be happier with the results Gravitichain delivered. They not only helped us reach our target audience but also provided invaluable insights that helped us optimize our marketing efforts.",
    },
    {
      pic: dummy2,
      content:
        "I couldn't be happier with the results Gravitichain delivered. They not only helped us reach our target audience but also provided invaluable insights that helped us optimize our marketing efforts.",
    },
    {
      pic: dummy1,
      content:
        "I couldn't be happier with the results Gravitichain delivered. They not only helped us reach our target audience but also provided invaluable insights that helped us optimize our marketing efforts.",
    },
    {
      pic: dummy2,
      content:
        "I couldn't be happier with the results Gravitichain delivered. They not only helped us reach our target audience but also provided invaluable insights that helped us optimize our marketing efforts.",
    },
    {
      pic: dummy1,
      content:
        "I couldn't be happier with the results Gravitichain delivered. They not only helped us reach our target audience but also provided invaluable insights that helped us optimize our marketing efforts.",
    },
  ];

  const programBox = [
    {
      icon: tick,
      text: "Early Stage Entrepreneurs",
    },
    {
      icon: tick,
      text: "Business Owners",
    },
    {
      icon: tick,
      text: "D2C/B2C/ B2B Businesses",
    },
    {
      icon: tick,
      text: "Marketers",
    },
  ];

  const faqns = [
    {
      id: 1,
      qn: "What is the duration of the consultation?",
    },
    {
      id: 2,
      qn: "I made the payment but didn’t receive any email",
    },
    {
      id: 3,
      qn: "Is the session recorded?",
    },
    {
      id: 4,
      qn: "Can I reschedule my appointment?",
    },
    {
      id: 5,
      qn: "What all platforms are covered in the session?",
    },
  ];

  const answers = [
    {
      id: 1,
      ans: "The consultation typically lasts between 1 to 1.5 hours.",
    },
    {
      id: 2,
      ans: "Please write to us at hello@gravitichain.com, and our awesome support team will get back to you within 24-48 hours.",
    },
    {
      id: 3,
      ans: "No, it is a completely LIVE session.",
    },
    {
      id: 4,
      ans: "Yes, you may reschedule your appointment once by requesting us via our email: hello@gravitichain.com.",
    },
    {
      id: 5,
      ans: "The session covers Google, Meta, LinkedIn, Reddit, Pinterest, and Twitter.",
    },
  ];

  // Function to convert HTML entities to actual characters
  function decodeHTMLEntities(text) {
    var tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    return tempElement.textContent || tempElement.innerText;
  }

  function getFormattedDate() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const today = new Date();
    const day = today.getDate();
    const month = months[today.getMonth()];
    const year = today.getFullYear();

    const formattedDate = `${month} ${day}, ${year}`;
    // console.log(formattedDate, 'formattedDate');
    setDate(formattedDate);
  }

  const handleQuestionClick = (questionId) => {
    setSelectedQuestionId((prevQuestionId) => {
      if (prevQuestionId === questionId) {
        // If the clicked question is the same as the previously selected one, hide the answer
        return null;
      } else {
        // Otherwise, show the answer for the clicked question
        return questionId;
      }
    });
  };

  useEffect(() => {
    getFormattedDate();
  }, []);

  return (
    <div className="homepage-container">
      <div className="bgImg-container">
        <div className="bg-img"></div>
        <div className="homepage-navbar">
          <div className="navbar">
            <div className="nav-logo">
              <img src={logo} alt="" />
            </div>
            <div className="nav-logo-mob">
              <img src={moblogo} alt="" />
            </div>
            <div className="nav-btns">
              <a href="#about-us">About us</a>
              {/* <div>
                <p>Contact Us</p>
              </div> */}
              <div
                className="book-btn"
                onClick={() => {
                  window.open("https://calendly.com/d/2t3-btc-px8", "_blank");
                }}
              >
                Book your consultation
              </div>
            </div>
            <div className="nav-btns-mob">
              <div
                className="mob-book-btn"
                onClick={() => {
                  window.open("https://calendly.com/d/2t3-btc-px8", "_blank");
                }}
              >
                Book Now
              </div>
              <div>
                <img src={downArrow1} alt="" />
              </div>
            </div>
          </div>
          <div className="navbar-line"></div>
        </div>
        <div className="hero-container">
          <div className="hero-text-div">
            <div className="hero-text">
              Get a 1:1 Consultation on your <br />{" "}
              <span> Digital Marketing Strategy</span>
            </div>
            <br />
            <div className="hero-sub-text">
              Get a 360&deg; audit on Google Ads, Meta Ads, LinkedIn Ads
            </div>{" "}
          </div>
          <div
            className="hero-btn"
            onClick={() => {
              window.open("https://calendly.com/d/2t3-btc-px8", "_blank");
            }}
          >
            Get 2X Returns @ <span className="highlight-txt">₹499</span>{" "}
            <span className="strike">₹1999</span>
          </div>
          <div className="hero-img-div">
            <div className="left-div">
              <div style={{ display: "flex" }}>
                <img src={mihir} alt="" />
              </div>
            </div>
            <div className="right-div">
              <div className="inst-by">Instructed by</div>
              <div className="name">Mihir Shah</div>
              <div className="designation">
                Marketing Head , Entrepreneur , Faculty @ NMIMS
              </div>
              <div className="about">
                Mihir Shah is a seasoned digital marketer and a business growth
                expert with over 10 years of experience across the globe with
                clients in various industries{" "}
              </div>
              <div>
                <img src={social} alt="" />
              </div>
            </div>
          </div>
          <div className="numbers-div">
            <div className="each-no-box">
              <div className="color-txt">100+ Cr</div>
              <div className="small-txt">In Ad Spends</div>
            </div>
            <div className="each-no-box">
              <div className="color-txt">7000+</div>
              <div className="small-txt">Business’s Mentored</div>
            </div>
            <div className="each-no-box">
              <div className="color-txt">100000+</div>
              <div className="small-txt">Students Trained</div>
            </div>
          </div>
        </div>
      </div>

      <div className="wat-u-get-container">
        <div className="wat-u-get-div">
          <div className="wat-u-get-text">What will you get</div>
          <div>
            <img src={star} alt="" />
          </div>
        </div>
        <div className="boxes-container">
          {boxesData.map((e, i) => {
            // Rendering the data with the degree symbol
            const auditTitle = decodeHTMLEntities(e.title);
            return (
              <div className="each-box" key={i}>
                <div>
                  <img src={e.icon} alt="" />
                </div>
                <div
                  className="box-head"
                  dangerouslySetInnerHTML={{ __html: auditTitle }}
                />
                <div className="box-sub-head">{e.subhead}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="program-container">
        <div className="program-div">
          <div className="program-text">Who is this program for?</div>
          <div>
            <img src={star1} alt="" />
          </div>
        </div>
        <div className="program-boxes">
          {programBox.map((e, i) => {
            return (
              <div className="each-program-box" key={i}>
                <div>
                  <img src={e.icon} alt="" />
                </div>
                <div>{e.text}</div>
              </div>
            );
          })}
        </div>
        <div
          className="program-btn"
          onClick={() => {
            window.open("https://calendly.com/d/2t3-btc-px8", "_blank");
          }}
        >
          Get 2X Returns @ <span className="highlight-txt">₹499</span>{" "}
          <span className="strike">₹1999</span>
        </div>
      </div>

      <div className="testimonial-section">
        <div className="testimonial-div">
          <div className="testimonial-text">
            More than 5000+ businesses <br /> profited from the 1:1 mentorship
          </div>
          <div className="testimonial-text-star">
            <img src={star} alt="" />
          </div>
        </div>
        <div className="testimonial-container">
          {testimonialData.map((e, i) => {
            return (
              <div className="each-testimonial" key={i}>
                <div className="testimonial-data">
                  <div className="name">Name</div>
                  <div className="des">Designation</div>
                  <div className="stars">
                    <img src={stars} alt="" />
                  </div>
                  <div className="quotee">
                    <img src={quote} alt="" />
                  </div>
                  <div className="review">{e.content}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="offer-section">
        <div className="ellipse1"></div>
        <div className="ellipse2"></div>
        <div className="offer-div">
          <div className="offer-text">Get the offer while it lasts!</div>
          <div>
            <img src={star1} alt="" />
          </div>
        </div>
        <div className="offer-box">
          <div className="left-offer-box">
            <div className="one-one">
              1:1 Consultation on <br /> your Digital Marketing Strategy <br />
              (Save ₹1500)
            </div>
            <div className="one-one-mob">
              1:1 Consultation on your <br /> Digital Marketing Strategy <br />
              (Save ₹1500)
            </div>
            <div className="prices">
              <span className="price1">₹499</span>
              <span className="price2">₹1999</span>
            </div>
            <div className="process">
              <div className="each-process">
                <div className="tickk">
                  <img src={tick1} alt="" />
                </div>
                <div>
                  Enroll now and get your business <br /> accelerated
                </div>
              </div>
              <div className="each-process">
                <div className="tickk">
                  <img src={tick1} alt="" />
                </div>
                <div>
                  Enjoy the discounted price of <br /> ₹499 and save a total of
                  ₹1500.
                </div>
              </div>
              <div className="each-process">
                <div className="tickk">
                  <img src={tick1} alt="" />
                </div>
                <div>
                  The launch offer expires tonight. <br /> {date}
                </div>
              </div>
            </div>
            <div className="note-div">
              Note: No exceptions would be made beyond the offer <br /> expiry
              date. The prices would go up and no free <br /> bonuses
            </div>
            <div
              className="enroll-btn"
              onClick={() => {
                window.open("https://calendly.com/d/2t3-btc-px8", "_blank");
              }}
            >
              ENROLL NOW
            </div>
          </div>
          <div className="right-offer-box">
            <div className="wat-text">What you’ll get...</div>
            <div className="right-process">
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Complete digital marketing strategy audit</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Google Ads consultation</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Meta Ads consultation</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>LinkedIn Ads consultation</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Lead Generation framework</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Competitor Analysis</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Targeting Analysis</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Creative and Copy Analysis</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Pixel and tracking code</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Funnel analysis</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Campaign optimization</div>
              </div>
              <div className="each-rp">
                <div>
                  <img src={tick2} alt="" />
                </div>
                <div>Remarketing Strategy</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="program-btn"
          onClick={() => {
            window.open("https://calendly.com/d/2t3-btc-px8", "_blank");
          }}
        >
          Get 2X Returns @ <span className="highlight-txt">₹499</span>{" "}
          <span className="strike">₹1999</span>
        </div>
      </div>

      <div className="about-company-container" id="about-us">
        <div className="ellipse1"></div>
        <div className="ellipse2"></div>
        <div className="about-company-div">
          <div className="about-company-text">About our company</div>
          <div>
            <img src={star1} alt="" />
          </div>
        </div>
        <div className="about-company-box">
          <div className="about-web">
            <img src={about} alt="" />
          </div>
          <div className="about-mob">
            <img src={aboutImg} alt="" />
          </div>
          <div className="ac-right-div">
            <div className="ac-right-box"></div>
            <div className="ac-right-text-div">
              {qnAndAns.map((e, i) => {
                return (
                  <div className="each-qn" key={i}>
                    <div className="qn">{e.ques}</div>
                    <div className="ans">{e.answer}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="faq-section">
        <div className="faq-div">
          <div className="faq-text">Frequently Asked Questions</div>
          <div>
            <img src={star} alt="" />
          </div>
        </div>
        <div className="faqs">
          {faqns.map((e, i) => {
            const isQuestionSelected = selectedQuestionId === e.id;
            return (
              <div
                className="each-faq"
                key={i}
                onClick={() => handleQuestionClick(e?.id)}
              >
                <div className="qn-div">
                  <div>{e.qn}</div>
                  <div
                    style={{
                      transform: isQuestionSelected
                        ? "rotate(180deg)"
                        : "rotate(0)",
                    }}
                  >
                    <img src={downArrow} alt="" />
                  </div>
                </div>
                {isQuestionSelected && (
                  <div className="hidden-div">
                    {answers.find((answer) => answer.id === e.id).ans}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div
          className="program-btn"
          onClick={() => {
            window.open("https://calendly.com/d/2t3-btc-px8", "_blank");
          }}
        >
          Get 2X Returns @ <span className="highlight-txt">₹499</span>{" "}
          <span className="strike">₹1999</span>
        </div>
      </div>

      <div className="footer-section">
        <div className="footer-container">
          <div className="max-section">
            <div className="max-container">
              <div className="max-text-div">
                <div className="text">
                  <div className="left-star">
                    <img src={star} alt="" />
                  </div>
                  <div>Maximize ROI, Maximize Results</div>
                </div>
                <div className="text">
                  <div className="right-star">
                    <img src={star} alt="" />
                  </div>
                  <div>Let your spends justify your returns</div>
                </div>
              </div>
              <div
                className="program-btn"
                onClick={() => {
                  window.open("https://calendly.com/d/2t3-btc-px8", "_blank");
                }}
              >
                Get 2X Returns @ <span className="highlight-txt">₹499</span>{" "}
                <span className="strike">₹1999</span>
              </div>
            </div>
          </div>
          <div className="logo-section">
            <div>
              <img src={logo} alt="" />
            </div>
          </div>
          <div className="footer-section1">
            <div className="social-logos">
              {/* <div>
                <img src={twitter} alt="" />
              </div> */}
              <div
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/profile.php?id=100095549852545&mibextid=LQQJ4d",
                    "_blank"
                  );
                }}
              >
                <img src={fb} alt="" />
              </div>
              <div
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/gravitichaincom",
                    "_blank"
                  );
                }}
              >
                <img src={linkedin} alt="" />
              </div>
              <div
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/gravitichain/",
                    "_blank"
                  );
                }}
              >
                <img src={insta} alt="" />
              </div>
            </div>
            <div className="copyright">
              Copyright @2023 Gravitichain | Powered by Starfish, All rights
              reserved
            </div>
          </div>
          <div className="mob-logo-section">
            <div>
              <img src={logo} alt="" />
            </div>
          </div>
        </div>
        <div className="mob-footer-section">
          <div className="mob-social-logos">
            {/* <div>
              <img src={twitter} alt="" />
            </div> */}
            <div
              onClick={() => {
                window.open(
                  "https://www.facebook.com/profile.php?id=100095549852545&mibextid=LQQJ4d",
                  "_blank"
                );
              }}
            >
              <img src={fb} alt="" />
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/gravitichaincom",
                  "_blank"
                );
              }}
            >
              <img src={linkedin} alt="" />
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://www.instagram.com/gravitichain/",
                  "_blank"
                );
              }}
            >
              <img src={insta} alt="" />
            </div>
          </div>
          <div className="mob-copyright">
            Copyright @2023 Gravitichain | Powered by Starfish, All rights
            reserved
          </div>
        </div>
        <div className="mob-bottom-btn">
          <div className="prices">
            <span className="price1">₹499</span>
            <span className="price2">₹1999</span>
          </div>
          <div
            className="mob-book-btn"
            onClick={() => {
              navigate("/legacy/payment");
            }}
          >
            Book Now
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
