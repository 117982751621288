import React from "react";
import arrowRight from "../img/arrow-right-solid.svg";

export const DevContainer = () => {
  return (
    <section class="Dev__container">
      <div class="Dev__bg"></div>
      <div class="Dev__content">
        <div class="Dev__content__innerbox__bg"></div>
        <div class="Dev__content__innerbox">
          <div class="Dev__content__innerbox__left">
            <h3>SOFTWARE DEVELOPMENT</h3>
            <h2>The Next Step Towards Deployment</h2>
            <p>
              Our team of business and software developers will take the time to
              understand your business and use that insight to build a solution
              that is tailored to your customers.
            </p>
            <br />
            <p>
              With your white-paper, business plan, and input, we can execute
              your vision while expediting your time to market.
            </p>
          </div>
          <div class="Dev__content__innerbox__right">
            <a href="/portfolio/globalxchange/">
              <button class="CaseStudyButton">
                <img src={arrowRight} draggable="false" alt="arrowRight" />
                See Case Study
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
