import React from "react";
import facebook from "../img/facebook-f-brands.svg";
import linkedin from "../img/linkedin-in-brands.svg";

export const HeroContainer = () => {
  return (
    <section class="Hero__container">
      <div class="Hero__title">
        <h3>GRAVITICHAIN STUDIOS</h3>
        <h1>
          Blockchain and Software
          <br />
          Development
        </h1>
        <p>
          We specialise in serving blockchain start-ups and traditional
          mid-sized companies.
        </p>
        <a href="#dev">
          <button>
            <div>+</div>
          </button>
        </a>
      </div>

      <div class="Hero__social">
        <a href="https://www.facebook.com/GravitiChain/">
          <button class="Hero__social__facebook">
            <img src={facebook} draggable="false" alt="facebook" />
          </button>
        </a>
        <a href="https://www.linkedin.com/company/gravitichain/">
          <button class="Hero__social__linkedin">
            <img src={linkedin} draggable="false" alt="linkedin" />
          </button>
        </a>
      </div>
    </section>
  );
};
