import React, { useContext } from "react";
import "./hiddenNavbar.css";
import logoWhite from "../../img/logo-white.png";
import { GlobalContext } from "../../context/globalContext";

export const Hiddennavbar = () => {
  const { topNavbar, setTopNavbar } = useContext(GlobalContext);

  const toggleNavbar = () => {
    setTopNavbar(!topNavbar);
  };

  return <div id="hiddenNavbar"></div>;
};
