import React from "react";
import arrowRightSolid from "../img/arrow-right-solid.svg";

export const PricingContainer = () => {
  return (
    <section class="Pricing__container" id="pricing">
      <div class="Pricing__content">
        <div class="Pricing__content__image"></div>
        <div class="Pricing__content__body">
          <h3>FLEXIBLE</h3>
          <h2>Our Pricing Model</h2>

          <h4>Project Based</h4>
          <p>
            We build an entire application, or an object within an application,
            and charge an agreed-upon rate with a set completion date.
          </p>

          <h4>Time Based</h4>
          <p>
            We offer a fixed number of full-time developers who work alongside
            your team directly under your management.
          </p>
          <a href="#contact">
            <button class="CaseStudyButton">
              <img
                src={arrowRightSolid}
                draggable="false"
                alt="arrowRightSolid"
              />
              Get a Quote
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};
